<template>
  <div class="date-range-picker">
    <b-dropdown
      ref="dateRangeDropdownRef"
      :text="btnText"
      left
      variant="outline-primary"
      class="btn-block"
      @shown="onDateRangePickerShown"
    >
      <!--      offset="350"-->
      <b-dropdown-text>
        <b-row>
          <b-col
            cols="12"
            sm="4"
            lg="3"
          >
            <b-dropdown-item @click="applyDateRangePicker(dayjs(), 1, 'day')">
              {{ $t('shared.relative-time.today') }}
            </b-dropdown-item>
            <b-dropdown-item @click="applyDateRangePicker(dayjs().subtract(1, 'day'), 1, 'day')">
              {{ $t('shared.relative-time.yesterday') }}
            </b-dropdown-item>
            <b-dropdown-item @click="applyDateRangePicker(dayjs().startOf('week'), 7, 'day')">
              {{ $t('shared.relative-time.this-week') }}
            </b-dropdown-item>
            <b-dropdown-item @click="applyDateRangePicker(dayjs().subtract(1, 'week').startOf('week'), 7, 'day')">
              {{ $t('shared.relative-time.last-week') }}
            </b-dropdown-item>
            <b-dropdown-item @click="applyDateRangePicker(dayjs().add(1, 'month').startOf('month'), 1, 'month')">
              {{ $t('shared.relative-time.next-month') }}
            </b-dropdown-item>
            <b-dropdown-item @click="applyDateRangePicker(dayjs().startOf('month'), 1, 'month')">
              {{ $t('shared.relative-time.this-month') }}
            </b-dropdown-item>
            <b-dropdown-item @click="applyDateRangePicker(dayjs().subtract(1, 'month').startOf('month'), 1, 'month')">
              {{ $t('shared.relative-time.last-month') }}
            </b-dropdown-item>
            <b-dropdown-item @click="applyDateRangePicker(dayjs().subtract(29, 'day'), 30, 'day')">
              {{ $t('shared.relative-time.last-30-days') }}
            </b-dropdown-item>
            <b-dropdown-item @click="applyDateRangePicker(dayjs().subtract(89, 'day'), 90, 'day')">
              {{ $t('shared.relative-time.last-90-days') }}
            </b-dropdown-item>
            <!--            <b-dropdown-item @click="applyDateRangePicker(organizationCreatedAt, dayjs().diff(organizationCreatedAt.startOf('day'), 'days'), 'day')">-->
            <!--              {{ $t('shared.relative-time.from-the-beginning') }}-->
            <!--            </b-dropdown-item>-->
          </b-col>

          <b-col
            cols="12"
            sm="8"
            lg="9"
          >
            <div ref="dateRangePickerRef" />
          </b-col>

          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="outline-primary"
              @click="dateRangeDropdownRef.hide()"
            >
              {{ $t('shared.button.close') }}
            </b-button>
          </b-col>
        </b-row>
      </b-dropdown-text>

    </b-dropdown>

  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import setup from '@/views/_global/date-range-picker/setup'

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    btnText: {
      type: String,
      default: () => '',
    },
  },
  setup,
}
</script>

<style lang="scss">
.date-range-picker {
  ul {
    min-width: 340px;
    li {
      a {
        padding-top: 0.40rem;
        padding-bottom: 0.40rem;
        padding-left: 0.6rem;
      }
    }
  }
  @media (min-width: 576px) {
    ul {
      min-width: 500px;
    }
  }
  @media (min-width: 992px) {
    ul {
      min-width: 850px;
    }
  }

  .flatpickr-wrapper {
    > .flatpickr-calendar {
      border: none;
      box-shadow: none;
    }

    .flatpickr-days {
      > .dayContainer {
        > .flatpickr-day.hidden {
          display: block;
          visibility: hidden;
        }
      }
    }
  }

}
</style>
