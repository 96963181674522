<template>
  <div>
    <b-card :title="$t('report.generate-report')">
      <validation-observer ref="generateNewReportValidation">
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('report.reports.filters.dateRange')"
              rules="required"
            >
              <label for="dateRange">
                {{ $t('report.reports.filters.dateRange') }}
              </label>
              <date-range-picker
                v-model="dateRange"
                :btn-text="dateRangeString"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('report.reports.filters.report')"
              rules="required"
            >
              <label for="reportType">
                {{ $t('report.reports.filters.report') }}
              </label>
              <v-select
                id="reportType"
                v-model="reportType"
                :options="filteredReportTypes"
                :clearable="false"
                :searchable="false"
                :reduce="name => name.name"
                @option:selected="reportTypeChanged"
              >
                <span
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ $t(`report.reports.types.${option.name}`) }}
                </span>
                <template #option="{name}">
                  <span>{{ $t(`report.reports.types.${name}`) }}</span>
                </template>

              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            v-if="reportFormControls.customer"
            cols="12"
            md="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('report.reports.filters.customer')"
              :rules="reportFormControls.customerRequired ? 'required' : ''"
            >
              <label for="customer">
                {{ $t('report.reports.filters.customer') }}
              </label>
              <v-select
                id="customer"
                v-model="reportFilters.customer"
                :options="customers"
                :reduce="name => name.id"
                :filter="searchByValue"
              >
                <span
                  slot="selected-option"
                  slot-scope="option"
                >
                  <span v-if="option.id">
                    {{ option.name }}, {{ option.city }}
                  </span>
                </span>
                <template #option="{ name, city }">
                  <span>{{ name }}, {{ city }}</span>
                </template>

              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>

      <template #footer>

        <div class="text-right">

          <b-button
            variant="primary"
            :disabled="reportLoading"
            @click="requestNewReport"
          >
            {{ $t('report.add-report-to-generation-queue') }}
          </b-button>
        </div>

      </template>
    </b-card>

    <b-card no-body>

      <h4 class="pt-2 pl-2 pb-1">
        {{ $t('report.reports.list-title') }}
      </h4>
      <b-table
        ref="refReportsTable"
        class="position-relative"
        :items="reports"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('report.no-data-description') "
      >

        <!-- Column: type -->
        <template #cell(type)="data">
          {{ $t(`report.reports.types.${data.item.type}`) }}
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          {{ $t(`report.status.${data.item.status}`) }}
        </template>

        <!-- Column: customer -->
        <template #cell(customer)="data">
          <span v-if="data.item.customer">
            {{ data.item.customer.name }}, {{ data.item.customer.city }}
          </span>
        </template>

        <!-- Column: dateFrom -->
        <template #cell(dateFrom)="data">
          <span
            v-if="data.item.dateFrom === data.item.dateTo"
            class="text-nowrap"
          >
            {{ transformDateToHumanReadablePretty(new Date(data.item.dateFrom)) }}
          </span>
          <span
            v-else
            class="text-nowrap"
          >
            {{ transformDateToHumanReadablePretty(new Date(data.item.dateFrom)) }} - {{ transformDateToHumanReadablePretty(new Date(data.item.dateTo)) }}
          </span>
        </template>

        <!-- Column: requestedBy -->
        <template #cell(requestedBy)="data">
          {{ data.item.requestedBy.surname }} {{ data.item.requestedBy.name }}
        </template>

        <!-- Column: generatedAt -->
        <template #cell(generatedAt)="data">
          {{ transformDatetimeToHumanReadablePretty(data.item.updatedAt) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div
            v-if="canDownload(data.item)"
            class="text-nowrap"
          >
            <feather-icon
              v-if="downloading"
              icon="LoaderIcon"
              size="16"
            />
            <feather-icon
              v-else
              icon="DownloadIcon"
              class="cursor-pointer"
              size="16"
              @click="download(data.item.id)"
            />
          </div>

        </template>
      </b-table>

    </b-card>
  </div>
</template>

<script>
import { parseRequestError, transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import reportRequests from '@/http/requests/report/report'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton, BCard, BCol, BRow, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import guard from '@/guard'
import DateRangePicker from '@/views/_global/date-range-picker/DateRangePicker.vue'
import { transformDateToHumanReadablePretty } from '@/helpers/dateTimeHelper'
import dayjs from 'dayjs'

export default {
  name: 'ReportView',
  components: {
    DateRangePicker,

    vSelect,

    ValidationProvider,
    ValidationObserver,

    BCard,
    BButton,
    BCol,
    BRow,
    BTable,
  },
  data() {
    // noinspection JSPotentiallyInvalidConstructorUsage
    return {
      downloading: false,
      loading: true,
      reportLoading: false,
      reportType: null,
      reportTypes: [
        {
          name: 'customerSettlement',
          roles: ['ROLE_FINANCE_MANAGER'],
          params: {
            customer: true,
            customerRequired: false,
          },
        },
        {
          name: 'customerOrderDocuments',
          roles: ['ROLE_DATA_ANALYST'],
          params: {
            customer: true,
          },
        },
        {
          name: 'teamMembersSettlement',
          roles: ['ROLE_DATA_ANALYST'],
          params: {
            customer: false,
          },
        },
        {
          name: 'scheduleAvailability',
          roles: ['ROLE_HR_MANAGER'],
          params: {
            customer: false,
          },
        },
        {
          name: 'workSchedule',
          roles: ['ROLE_HR_MANAGER', 'ROLE_ADVANCED_DISPATCHER'],
          params: {
            customer: false,
          },
        },
        {
          name: 'payroll',
          roles: ['ROLE_HR_MANAGER'],
          params: {
            customer: false,
          },
        },
        {
          name: 'sentSms',
          roles: ['ROLE_ADMIN'],
          params: {
            customer: false,
          },
        },
      ],
      dateRange: [
        dayjs().startOf('month').toDate(),
        dayjs().endOf('month').toDate(),
      ],
      reportFilters: {
        customer: null,
      },
      reportFormControls: {
        customer: false,
        customerRequired: false,
      },
      tableColumns: [
        {
          label: this.$t('report.list.header.type'),
          key: 'type',
          sortable: true,
        },
        {
          label: this.$t('report.list.header.status'),
          key: 'status',
          sortable: true,
        },
        {
          label: this.$t('report.reports.filters.customer'),
          key: 'customer',
          sortable: true,
        },
        {
          label: this.$t('report.reports.filters.dateRange'),
          key: 'dateFrom',
          sortable: true,
        },
        {
          label: this.$t('report.list.header.requested-by'),
          key: 'requestedBy',
          sortable: true,
        },
        {
          label: this.$t('report.list.header.generated-at'),
          key: 'generatedAt',
          sortable: true,
        },
        {
          label: this.$t('shared.actions'),
          key: 'actions',
          value: null,
          class: 'actions-column',
        },
      ],
    }
  },
  computed: {
    customers() {
      return this.$store.getters['customer/getCustomers']
    },
    reports() {
      return this.$store.getters['report/getReports'].filter(report => this.canDownload(report))
    },
    me() {
      return this.$store.state.auth.activeUser
    },
    filteredReportTypes() {
      return this.reportTypes.filter(reportType => this.hasAtLeastOneRole(this.me.roles, reportType.roles))
    },
    dateRangeString() {
      return `${transformDateToHumanReadablePretty(this.dateRange[0])} - ${transformDateToHumanReadablePretty(this.dateRange[1])}`
    },
  },
  beforeMount() {
    Promise.all([
      this.$store.dispatch('customer/fetchCustomers', { limit: 1000, sortField: 'name' }),
      this.$store.dispatch('report/fetchReports'),
    ])
      .then(() => {
        this.loading = false
        this.reportType = this.filteredReportTypes[0].name
      })
  },
  mounted() {
    this.reportTypeChanged(this.filteredReportTypes[0])
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    transformDatetimeToHumanReadablePretty,
    transformDateToHumanReadablePretty,
    requestNewReport() {
      this.$refs.generateNewReportValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }
          window.toast.notify.success(this.$t('report.notify.requested.success_title'))
          this.reportLoading = true
          let payload = {
            type: this.reportType,
            dateFrom: dayjs(this.dateRange[0]).format('YYYY-MM-DD'),
            dateTo: dayjs(this.dateRange[1]).format('YYYY-MM-DD'),
          }
          payload = { ...payload, ...this.reportFilters }
          this.$store.dispatch('report/requestReport', payload)
            .then(() => window.toast.notify.success(this.$t('report.notify.generated.success_title')))
            .catch(err => parseRequestError(err))
            .finally(() => {
              this.reportLoading = false
            })
          return true
        })
    },
    download(id) {
      this.downloading = true
      reportRequests.downloadReport(id)
        .then(response => {
          const filename = response.headers['content-disposition'].replace('attachment; filename=', '')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.downloading = false
        })
    },
    reportTypeChanged(type) {
      Object.keys(type.params).forEach(param => {
        this.reportFormControls[param] = type.params[param]
        if (type.params[param]) {
          this.reportFilters[param] = null
        } else {
          delete this.reportFilters[param]
        }
      })
    },
    canDownload(report) {
      let canDownload = false
      this.filteredReportTypes.forEach(reportType => {
        if (!canDownload && reportType.name === report.type) {
          canDownload = true
        }
      })

      return canDownload
    },
    searchByValue(options, search) {
      // Split the query into multiple parts
      const queryParts = search.toLowerCase().split(/\s+/)
      return options.filter(item => {
        // Convert the value to lowercase for case-insensitive comparison
        const valueLower = `${item.name.toLowerCase()} ${item.city.toLowerCase()}`
        // Check if all parts of the query are present in the value
        return queryParts.every(part => valueLower.includes(part))
      })
    },
  },
}

</script>
